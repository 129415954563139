<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" (click)="navigateHome()">ZATLIVE</a>
  </div>
  <!-- <nb-select
    [selected]="currentTheme"
    (selectedChange)="changeTheme($event)"
    status="primary"
  >
    <nb-option *ngFor="let theme of themes" [value]="theme.value">
      {{ theme.name }}</nb-option
    >
  </nb-select> -->
</div>

<div class="header-container">
  <nb-actions size="small">

<!-- header.component.html --> 
  <nb-action class="control-item" icon="home-outline" nbTooltip="Go to User Dashboard" (click)="gotoUserDashboard()" nbTooltipPlacement="bottom" *ngIf="userDashboardVisible"></nb-action>
  <nb-action class="control-item" icon="shield-outline" nbTooltip="Go to Admin Dashboard" (click)="gotoAdminDashboard()" nbTooltipPlacement="bottom" *ngIf="adminDashboardVisible"></nb-action>


    <!-- <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <!-- <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.name"
      >
      </nb-user> -->

      <nb-user
        size="medium"
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.name"
        color="#00d68f"
        class="user"
        [picture]="this.UserImage"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
