import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authToken: any;
  user: any;
  tokenSubscription = new Subscription()
  timeout;

  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isLoggedInSubject.asObservable();

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService) {
    this.checkIsLoggedIn();
  }

  isAdmin(): boolean {
    const accessData = JSON.parse(localStorage.getItem('access'));
    return accessData && accessData.is_super_admin === true;
  }

  isPackageActivated() : boolean {
    const accessData = JSON.parse(localStorage.getItem('access'));
    
    if (accessData) {
        const liveTvEnabled = accessData.live_tv === true;
        const vodEnabled = accessData.vod === true;
        const advertisingEnabled = accessData.advertising === true;
        
        return liveTvEnabled || vodEnabled || advertisingEnabled;
    } else {
        return false;
    }
  }

  isTokenExpired(token: string): boolean {
    return this.jwtHelper.isTokenExpired(token);
  }

  getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  login(email: string, password: string): Observable<any> {
    const url = `${environment.apiUrl}login`;
    const body = { email, password };

    return this.http.post(url, body).pipe(
      tap((response) => {
        console.log("response----",response)
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('user_name', response.user_name);
        localStorage.setItem('profile_img', response.profile_image_filename);
        localStorage.setItem('access', JSON.stringify(response));
        this.isLoggedInSubject.next(true);
      })
    );
  }

  register(username: string, email: string, password: string): Observable<any> {
    const url = `${environment.apiUrl}register`;
    const body = { username, email, password };

    return this.http.post(url, body).pipe(
      tap((response) => {
        console.log(response);
      })
    );
  }

  logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_name');
    localStorage.removeItem('access');
    localStorage.removeItem('VerifyEmail');
    localStorage.removeItem('lastKnownRoute');
    this.isLoggedInSubject.next(false);
  }

  checkIsLoggedIn(): void {
    const token = localStorage.getItem('access_token');
    this.isLoggedInSubject.next(!!token);
  }

  GetUsers() {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}users`,{ headers: headers });
  }

  sendCode(email) {
    return this.http.post<any>(`${environment.apiUrl}forgot_password`,email);
  }

  resetPassword(configs) {
    return this.http.post<any>(`${environment.apiUrl}reset_password`,configs);
  }

  GetUserAccess() {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}user-permissions`,{ headers: headers });
  }

  updateUser(data: any) {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`,
    });

    return this.http.put<any>(`${environment.apiUrl}user`, data, { headers: headers });
  }

  createSubscription(data: any) {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}create_payment_intent`, data, { headers: headers });
  }

  //Admin

  GetAllVod() {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${auth_token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}admin/all-videos`,{ headers: headers });
  }

  GetAllLiveTV() {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${auth_token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}admin/live-tv-all`,{ headers: headers });
  }

  GetAllLiveTVAdver() {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${auth_token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}admin/ads-insertion-all-live`,{ headers: headers });
  }

  GetAllVODAdver() {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${auth_token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}admin/ads-insertion-all-vod`,{ headers: headers });
  }

  changePassword(data: any) {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}change_password`,data , { headers: headers });
  }

  UpdateUser(data: FormData) {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}update_profile`,data , { headers: headers });
  }

  getUserDetailsbyAuth() {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${auth_token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}user_details`,{ headers: headers });
  }

  GetAllSales() {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${auth_token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}admin/sales-summary`,{ headers: headers });
  }
}
