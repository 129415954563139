import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VisibilityService {
  userDashboardVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  adminDashboardVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {}
}
