import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class MediaService {
  fileSizeUnit: number = 1024;
  public isApiSetup = false;

  constructor(private http: HttpClient) {}

  getAllUploadVideo() {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}upload-video-all`,{ headers: headers });
  }

  getUploadVideobyID(id) {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}upload-video/${id}`,{ headers: headers });
  }

  deleteUploadVideoByID(data: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        video_id: data
      },
    };
    return this.http.delete<any>(`${environment.apiUrl}upload-video`, options);
  }

  getFileSize(fileSize: number): number {
    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSize = parseFloat((fileSize / this.fileSizeUnit).toFixed(2));
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSize = parseFloat(
          (fileSize / this.fileSizeUnit / this.fileSizeUnit).toFixed(2)
        );
      }
    }
    return fileSize;
  }

  getFileSizeUnit(fileSize: number) {
    let fileSizeInWords = 'bytes';

    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit) {
        fileSizeInWords = 'bytes';
      } else if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSizeInWords = 'KB';
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSizeInWords = 'MB';
      }
    }

    return fileSizeInWords;
  }

  uploadMedia(formData: any) {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`,
    });

    const req = new HttpRequest('POST', `${environment.apiUrl}upload-video`, formData, {
      headers: headers,
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req).pipe(
      tap((event) => {
        if (event.type === HttpEventType.UploadProgress && event.total) {
          console.log(`Progress: ${(event.loaded / event.total) * 100}%`);
        }
      }),
      map((response) => response),
      catchError((error) => {
        console.error('Error uploading video:', error);
        throw error;
      })
    );
  }

  updateVOD(data) {
    const auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}upload-video`, data, { headers: headers });
  }
}
