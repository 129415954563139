import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";
import { LayoutService } from "../../../@core/utils";
import { map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { AuthService } from "../../../services/auth.service";
// import { Router } from "@angular/router";
import { VisibilityService } from "../../../services/visibility.service";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  access;
  admin_access;
  User_img;

  UserDetails;
  UserImage;

  userDashboardVisible: boolean = true;
  adminDashboardVisible: boolean = false;

  themes = [
    {
      value: "default",
      name: "Light",
    },
    {
      value: "dark",
      name: "Dark",
    },
    {
      value: "cosmic",
      name: "Cosmic",
    },
    {
      value: "corporate",
      name: "Corporate",
    },
  ];

  currentTheme = "default";

  userMenu = [
    { title: "Profile", data: { id: "profile" } },
    { title: "Subscriptions", data: { id: "subscription" } },
    { title: "Orders", data: { id: "orders" } },
    { title: "Log out", data: { id: "logout" } },
  ];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: AuthService,
    private router: Router,
    public visibilityService: VisibilityService
  ) {
    this.menuService
      .onItemClick()
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: { tag: string; item: any }) => {
        if (event.item.data && event.item.data.id === "logout") {
          this.authService.logout();
          this.router.navigate(["/auth/login"]);
        }
        if (event.item.data && event.item.data.id === "subscription") {
          // this.authService.logout();
          this.router.navigate(["/pages/upgrade/subscription"]);
        }
        if (event.item.data && event.item.data.id === "profile") {
          // this.authService.logout();
          this.router.navigate(["/pages/profile"]);
        }
        if (event.item.data && event.item.data.id === "orders") {
          // this.authService.logout();
          this.router.navigate(["/pages/orders"]);
        }
      });
  }

  ngOnInit() {
    this.access = JSON.parse(localStorage.getItem("access"));
    this.admin_access = this.access.is_super_admin;
    this.authService.getUserDetailsbyAuth().subscribe((res) => {
      this.UserDetails = res;
      this.UserImage = this.UserDetails.profile_image_url
      console.log('this.UserImage',this.UserImage);
    });
    console.log("this.admin_access", this.admin_access);
    if (!this.admin_access) {
      this.userDashboardVisible = false;
      this.adminDashboardVisible = false;
    } else {
      const lastKnownRoute = localStorage.getItem("lastKnownRoute");
      if (lastKnownRoute) {
        this.userDashboardVisible = lastKnownRoute !== "/pages/dashboard";
        this.adminDashboardVisible =
          lastKnownRoute !== "/pages/admin-dashboard";
      }

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          // Hide buttons when navigation starts
          this.userDashboardVisible = false;
          this.adminDashboardVisible = false;
        } else if (event instanceof NavigationEnd) {
          // Show buttons when navigation ends
          const currentRoute = this.router.url;
          if (currentRoute === "/pages/dashboard") {
            this.userDashboardVisible = false;
            this.adminDashboardVisible = true;
            localStorage.setItem("lastKnownRoute", currentRoute);
          } else if (currentRoute === "/pages/admin-dashboard") {
            this.userDashboardVisible = true;
            this.adminDashboardVisible = false;
            localStorage.setItem("lastKnownRoute", currentRoute);
          } else {
            // For other routes, set button visibility directly
            this.userDashboardVisible =
              lastKnownRoute === "/pages/admin-dashboard";
            this.adminDashboardVisible = lastKnownRoute === "/pages/dashboard";
          }
        }
      });
    }

    this.currentTheme = this.themeService.currentTheme;
    this.user = {
      // name: localStorage.getItem("user_name").split(" ")[0],
    };

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));
  }

  gotoUserDashboard() {
    this.router.navigate(["/pages/dashboard"]);
  }

  gotoAdminDashboard() {
    this.router.navigate(["/pages/admin-dashboard"]);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    if (this.admin_access){
      this.router.navigate(["/pages/admin-dashboard"]);
  
    }
    else {
      this.router.navigate(["/pages/dashboard"]);
    }
  }
}
